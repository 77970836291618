var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.siteId !== null && _vm.dataLoaded)?_c('div',{staticClass:"bases-container"},[_c('a-row',{staticStyle:{"marginBottom":"20px"}},[_c('a-row',[_c('div',{staticClass:"table-gadgets"},[(_vm.isSiteManager)?_c('a-col',{attrs:{"span":2,"offset":2}},[_c('a-button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.disableAddBase,"type":"primary"},on:{"click":function($event){return _vm.addBase()}}},[_vm._v(" "+_vm._s(_vm.$t("form.add"))+" ")])],1):_vm._e(),_c('a-col',{attrs:{"span":8,"offset":_vm.isSiteManager ? 10 : 2}},[_c('a-input-search',{staticClass:"input-search-table",attrs:{"placeholder":"Chercher une base..."},model:{value:(_vm.searchBase),callback:function ($$v) {_vm.searchBase=$$v},expression:"searchBase"}})],1)],1)]),_c('a-row',{staticStyle:{"marginTop":"20px"},attrs:{"type":"flex"}},[_c('a-col',{attrs:{"span":20,"offset":2}},[_c('a-table',{staticClass:"baseList-table",attrs:{"columns":_vm.columns,"dataSource":_vm.resultBaseSearch,"rowKey":"id","pagination":_vm.pagination},on:{"change":_vm.onTableChange},scopedSlots:_vm._u([{key:"baseName",fn:function(text, record){return [(_vm.editingBase && record.id === _vm.editingBase.id)?_c('a-form-item',{attrs:{"validate-status":_vm.invalidFields.indexOf('baseName') === -1 ? '' : 'error'}},[_c('a-input',{ref:"baseName",staticStyle:{"margin":"-5px 0"},attrs:{"value":_vm.editingBase.baseName},on:{"change":function (e) { return _vm.handleChange('baseName', e.target.value); },"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () { return _vm.save(_vm.editingBase); }).apply(null, arguments)}}})],1):[_vm._v(_vm._s(text))]]}},_vm._l(([
              'serialNumber',
              'softwareVersion',
              'hardwareVersion',
              'droneVersion',
              'address',
              'latitude',
              'longitude' ]),function(col){return {key:col,fn:function(text, record){return [_c('div',{key:col},[(_vm.editingBase && record.id === _vm.editingBase.id)?_c('a-form-item',{attrs:{"validate-status":_vm.invalidFields.indexOf(col) === -1 ? '' : 'error'}},[_c('a-input',{ref:col,refInFor:true,staticStyle:{"margin":"-5px 0"},attrs:{"disabled":col === 'serialNumber'
                      ? _vm.editingBase.id !== 'new'
                        ? true
                        : false
                      : false,"value":_vm.editingBase[col]},on:{"change":function (e) { return _vm.handleChange(col, e.target.value); },"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () { return _vm.save(_vm.editingBase); }).apply(null, arguments)}}})],1):[_vm._v(_vm._s(text))]],2)]}}}),{key:"action",fn:function(text, record){return [(_vm.editingBase && record.id === _vm.editingBase.id)?_c('span',[_c('a-row',{staticClass:"btn-form"},[_c('a-col',{attrs:{"span":12}},[_c('a-button',{staticClass:"btn btn-editing",attrs:{"type":"link","title":_vm.$t('form.save')},on:{"click":function () { return _vm.save(_vm.editingBase); }}},[_c('a-icon',{staticClass:"btn-icon editing-form",attrs:{"type":"check-circle"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-button',{staticClass:"btn btn-cancel",attrs:{"type":"link","title":_vm.$t('form.cancel')},on:{"click":function () { return _vm.cancel(); }}},[_c('a-icon',{staticClass:"btn-icon editing-form",attrs:{"type":"close-circle"}})],1)],1)],1)],1):_vm._e(),(_vm.editingBase === null)?_c('span',[_c('a-dropdown',[_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{key:"edit",on:{"click":function($event){return _vm.edit(record)}}},[_c('a-icon',{attrs:{"type":"edit"}}),_vm._v(_vm._s(_vm.$t("form.edit"))+" ")],1),_c('a-menu-item',{key:"delete",on:{"click":function($event){return _vm.confirmDelete(record)}}},[_c('a-icon',{attrs:{"type":"delete"}}),_vm._v(_vm._s(_vm.$t("form.delete")))],1)],1),_c('a-button',{staticStyle:{"margin-left":"8px"}},[_c('a-icon',{attrs:{"type":"more"}})],1)],1)],1):_vm._e()]}}],null,true)})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }