<template>
  <div>
    <a-row>
      <a-col
        :xs="{ span: 18, offset: 8 }"
        :sm="{ span: 18, offset: 5 }"
        :md="{ span: 16, offset: 5 }"
        :lg="{ span: 12, offset: 6 }"
        :xl="{ span: 12, offset: 8 }"
        :xxl="{ span: 12, offset: 8 }"
      >
        <sites-selector style="margin: 0px 0px 30px 15px;" />
      </a-col>
    </a-row>
    <a-row>
      <BasesManagement />
    </a-row>
  </div>
</template>

<script>
import SitesSelector from "../SitesSelector";
import BasesManagement from "./BasesManagement";

export default {
  name: "basesEdit",
  components: {
    SitesSelector,
    BasesManagement,
  },
};
</script>
