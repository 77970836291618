<template>
  <div class="bases-container" v-if="siteId !== null && dataLoaded">
    <a-row style="marginBottom: 20px;">
      <a-row>
        <div class="table-gadgets">
          <a-col v-if="isSiteManager" :span="2" :offset="2">
            <a-button
              :disabled="disableAddBase"
              class="btn btn-primary"
              type="primary"
              @click="addBase()"
            >
              {{ $t("form.add") }}
            </a-button>
          </a-col>
          <a-col :span="8" :offset="isSiteManager ? 10 : 2">
            <a-input-search
              class="input-search-table"
              placeholder="Chercher une base..."
              v-model="searchBase"
            />
          </a-col>
        </div>
      </a-row>
      <a-row type="flex" style="marginTop: 20px;">
        <a-col :span="20" :offset="2">
          <a-table
            class="baseList-table"
            :columns="columns"
            :dataSource="resultBaseSearch"
            rowKey="id"
            :pagination="pagination"
            @change="onTableChange"
          >
            <template slot="baseName" slot-scope="text, record">
              <a-form-item
                v-if="editingBase && record.id === editingBase.id"
                :validate-status="
                  invalidFields.indexOf('baseName') === -1 ? '' : 'error'
                "
              >
                <a-input
                  style="margin: -5px 0"
                  :value="editingBase.baseName"
                  @change="(e) => handleChange('baseName', e.target.value)"
                  @keyup.enter="() => save(editingBase)"
                  ref="baseName"
                />
              </a-form-item>
              <template v-else>{{ text }}</template>
            </template>
            <template
              v-for="col in [
                'serialNumber',
                'softwareVersion',
                'hardwareVersion',
                'droneVersion',
                'address',
                'latitude',
                'longitude',
              ]"
              :slot="col"
              slot-scope="text, record"
            >
              <div :key="col">
                <a-form-item
                  v-if="editingBase && record.id === editingBase.id"
                  :validate-status="
                    invalidFields.indexOf(col) === -1 ? '' : 'error'
                  "
                >
                  <a-input
                    :disabled="
                      col === 'serialNumber'
                        ? editingBase.id !== 'new'
                          ? true
                          : false
                        : false
                    "
                    style="margin: -5px 0"
                    :value="editingBase[col]"
                    @change="(e) => handleChange(col, e.target.value)"
                    v-on:keyup.enter="() => save(editingBase)"
                    :ref="col"
                  />
                </a-form-item>
                <template v-else>{{ text }}</template>
              </div>
            </template>
            <template slot="action" slot-scope="text, record">
              <span v-if="editingBase && record.id === editingBase.id">
                <a-row class="btn-form">
                  <a-col :span="12">
                    <a-button
                      @click="() => save(editingBase)"
                      type="link"
                      class="btn btn-editing"
                      :title="$t('form.save')"
                    >
                      <a-icon
                        class="btn-icon editing-form"
                        type="check-circle"
                      />
                    </a-button>
                  </a-col>
                  <a-col :span="12">
                    <a-button
                      @click="() => cancel()"
                      type="link"
                      class="btn btn-cancel"
                      :title="$t('form.cancel')"
                    >
                      <a-icon
                        class="btn-icon editing-form"
                        type="close-circle"
                      />
                    </a-button>
                  </a-col>
                </a-row>
              </span>
              <span v-if="editingBase === null">
                <a-dropdown>
                  <a-menu slot="overlay">
                    <a-menu-item key="edit" @click="edit(record)">
                      <a-icon type="edit" />{{ $t("form.edit") }}
                    </a-menu-item>
                    <a-menu-item key="delete" @click="confirmDelete(record)">
                      <a-icon type="delete" />{{
                        $t("form.delete")
                      }}</a-menu-item
                    >
                  </a-menu>
                  <a-button style="margin-left: 8px">
                    <a-icon type="more" />
                  </a-button>
                </a-dropdown>
              </span>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Row,
  Col,
  Table,
  Button,
  Dropdown,
  Modal,
  Form,
  Input,
} from "ant-design-vue";
import { isGpsCoordinateValid } from "../../Flightplans/FormValidation";
import { isSiteManager } from "../../../utils/userAuth";
import { siteTypes } from "../../../store/mutation-types";

Vue.use(Row);
Vue.use(Col);
Vue.use(Table);
Vue.use(Button);
Vue.use(Form);
Vue.use(Input);
Vue.use(Dropdown);
Vue.use(Modal);

let sitesUnwatcher = null;
let currentSiteIdUnwatcher = null;

export default {
  name: "baseList",
  computed: {
    isSiteManager() {
      return isSiteManager();
    },
    disableAddBase() {
      return this.editingBase !== null;
    },
    siteId() {
      return this.$store.state.sites.currentSiteId;
    },
    resultBaseSearch() {
      if (this.searchBase) {
        return this.bases.filter((item) => {
          return this.searchBase
            .toLowerCase()
            .split(" ")
            .every((v) => item.baseName.toLowerCase().includes(v));
        });
      } else {
        return this.bases;
      }
    },
    columns() {
      const columns = [
        {
          title: this.$t("common.name"),
          dataIndex: "baseName",
          key: "baseName",
          width: "10%",
          scopedSlots: { customRender: "baseName" },
        },
        {
          title: this.$t("bases.serialNumber"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          width: "10%",
          scopedSlots: { customRender: "serialNumber" },
        },
        {
          title: this.$t("bases.softwareVersion"),
          dataIndex: "softwareVersion",
          key: "softwareVersion",
          width: "10%",
          scopedSlots: { customRender: "softwareVersion" },
        },
        {
          title: this.$t("bases.hardwareVersion"),
          dataIndex: "hardwareVersion",
          key: "hardwareVersion",
          width: "10%",
          scopedSlots: { customRender: "hardwareVersion" },
        },
        {
          title: this.$t("bases.droneVersion"),
          dataIndex: "droneVersion",
          key: "droneVersion",
          width: "10%",
          scopedSlots: { customRender: "droneVersion" },
        },
        {
          title: this.$t("points.latitude"),
          dataIndex: "latitude",
          key: "latitude",
          width: "12%",
          scopedSlots: { customRender: "latitude" },
        },
        {
          title: this.$t("points.longitude"),
          dataIndex: "longitude",
          key: "longitude",
          width: "12%",
          scopedSlots: { customRender: "longitude" },
        },
        {
          title: this.$t("bases.address"),
          dataIndex: "address",
          key: "address",
          width: "20%",
          scopedSlots: { customRender: "address" },
        },
      ];
      if (this.isSiteManager) {
        columns.push({
          dataIndex: "action",
          width: "5%",
          scopedSlots: { customRender: "action" },
        });
      }
      return columns;
    },
  },
  data() {
    return {
      eventBus: new Vue(),
      dataLoaded: false,
      editingBase: null,
      bases: [],
      searchBase: null,
      pagination: {
        defaultCurrent: 1,
        current: 1,
      },
      invalidFields: [],
    };
  },
  created() {
    sitesUnwatcher = this.$store.subscribe((mutation) => {
      if (mutation.type === siteTypes.FETCH_SITES) {
        this.refreshBasesFromStore();
        this.dataLoaded = true;
      }
    });

    currentSiteIdUnwatcher = this.$store.watch(
      (state) => state.sites.currentSiteId,
      () => {
        this.refreshBasesFromStore();
        this.dataLoaded = true;
        this.eventBus.$emit("site-changed");
      }
    );
  },
  mounted() {
    if (this.siteId) {
      if (this.$store.state.sites.lastFetch === null) {
        this.$store.dispatch("fetchSites");
      } else {
        this.refreshBasesFromStore();
        this.dataLoaded = true;
      }
    }
  },
  beforeDestroy() {
    if (currentSiteIdUnwatcher) {
      currentSiteIdUnwatcher();
    }

    if (sitesUnwatcher) {
      sitesUnwatcher();
    }
  },
  methods: {
    onTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
    },
    refreshBasesFromStore() {
      if (this.siteId !== null) {
        this.bases = JSON.parse(
          JSON.stringify(this.$store.getters.getSiteById(this.siteId).bases)
        );
      }
    },
    handleChange(field, value) {
      if (this.editingBase) {
        this.editingBase[field] = value;
      }
    },
    validateBase(base) {
      let invalidFields = [];
      if (base.baseName === "") {
        invalidFields.push("baseName");
      }
      if (base.serialNumber === "") {
        invalidFields.push("serialNumber");
      }
      if (base.softwareVersion === "") {
        invalidFields.push("softwareVersion");
      }
      if (base.hardwareVersion === "") {
        invalidFields.push("hardwareVersion");
      }
      if (base.droneVersion === "") {
        invalidFields.push("droneVersion");
      }
      if (base.address === "") {
        invalidFields.push("address");
      }
      if (!isGpsCoordinateValid(base.latitude)) {
        invalidFields.push("latitude");
      }
      if (!isGpsCoordinateValid(base.longitude)) {
        invalidFields.push("longitude");
      }
      this.invalidFields = invalidFields;
      return invalidFields.length === 0;
    },
    cancel() {
      this.editingBase = null;
      this.invalidFields = [];
      var index = this.bases.findIndex((b) => b.id === "new");
      if (index > -1) {
        this.bases.splice(index, 1);
      }
    },
    addBase() {
      this.pagination.current = 1;

      const newBase = {
        id: "new",
        baseName: "",
        serialNumber: "",
        softwareVersion: "",
        hardwareVersion: "",
        droneVersion: "",
        address: "",
        latitude: 0,
        longitude: 0,
      };

      this.bases.unshift(newBase);
      this.editingBase = newBase;
      this.$nextTick(() => {
        this.$refs["baseName"].focus();
      });
    },
    save(base) {
      if (Object.hasOwnProperty.call(base, "id")) {
        if (this.validateBase(base)) {
          base.latitude = parseFloat(base.latitude);
          base.longitude = parseFloat(base.longitude);
          let isNew = base.id === "new";
          let promise;
          if (isNew) {
            promise = this.$store.dispatch("addBase", {
              site: this.$store.getters.getSiteById(this.siteId),
              base,
            });
          } else {
            promise = this.$store.dispatch("updateBase", {
              site: this.$store.getters.getSiteById(this.siteId),
              base,
            });
          }
          promise.then(() => {
            this.editingBase = null;
            this.refreshBasesFromStore();
          });
        }
      }
    },
    edit(target) {
      this.editingBase = { ...target };
    },
    confirmDelete(target) {
      Modal.confirm({
        title: this.$t("form.deleteConfirm"),
        onOk: () => {
          this.$store
            .dispatch("deleteBase", {
              site: this.$store.getters.getFSiteById(this.siteId),
              base: target,
            })
            .then(() => this.refreshBasesFromStore());
        },
        onCancel: () => {},
      });
    },
  },
};
</script>

<style lang="less" scoped>
 .btn-form {
    height: 2.5rem;
    width: 3.75rem;
    font-size: 1.5rem;
  }

  .btn-editing{
    margin-bottom: 1.1rem;
    font-size: 1.5rem;
    color: #f0de00
  }

  .btn-cancel {
    margin-bottom: 1.1rem;
    font-size: 1.5rem;
    color: red;
  }
</style>
