<template>
  <div>
    <a-row
      type="flex"
      align="middle"
      :gutter="16"
    >
      <a-col >
        <label class="label-site">
          {{ $t('sites.selectedSite') }}: 
        </label>
      </a-col>
      <a-col class="column-select-site">
        <a-select
          @change="onSiteChanged"
          :value="currentSiteId"
        >
          <a-select-option
            v-for="site in sites"
            v-bind:key="site.id"
            :value="site.id"
          >
            {{ site.name }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
  </div>
</template>

<script>

import Vue from 'vue'
import { Col, Row, Input, Select, Button } from 'ant-design-vue'

Vue.use(Col)
Vue.use(Row)
Vue.use(Input)
Vue.use(Select)
Vue.use(Button)

export default {
  name: 'sites-selector',
  computed: {
    sites () {
      return this.$store.state.sites.sites
    },
    currentSiteId () {
      return this.$store.state.sites.currentSiteId
    }
  },
  methods: {
    onSiteChanged (id) {
      this.$store.dispatch('setCurrentSiteId', id)
    }
  },
  mounted () {
    if (this.$store.state.sites.lastFetch === null) {
      this.$store.dispatch('fetchSites')
    }
  }
}
</script>

<style lang="less" scoped>
.label-site {
  font-family: "Lato Bold";
  font-size: 20px;
}
.column-select-site {
  margin-top: -1%;
}
.ant-select {
  width: 200px;
}
</style>